import React from 'react'
import Head from 'next/head'

export interface SeoProps {
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  mobile?: string
  xxl?: string
  xl?: string
  lg?: string
  md?: string
  sm?: string
  xs?: string
}

export const Seo: React.FC<SeoProps> = props => {
  const { deskL, deskS, tabletL, tabletP, mobile, xxl, xl, lg, md, sm, xs } = props
  const deskSMaxWidth = deskL ? '1439px' : '1440px'
  const tabletLMaxWidth = deskS && deskS !== tabletL ? '1279px' : deskSMaxWidth
  const tabletPMaxWidth = tabletL && tabletL !== tabletP ? '1023px' : tabletLMaxWidth
  const mobileMaxWidth = tabletP && tabletP !== mobile ? '600px' : tabletPMaxWidth

  return (
    <Head>
      {deskL && (
        <link key={deskL} rel="preload" as="image" media="(min-width: 1440px)" href={deskL} fetchpriority={'high'} />
      )}
      {deskS && (
        <link
          key={deskS}
          rel="preload"
          as="image"
          media={`(min-width: 1280px) and (max-width: ${deskSMaxWidth})`}
          href={deskS}
          fetchpriority={'high'}
        />
      )}
      {tabletL && (
        <link
          key={tabletL}
          rel="preload"
          as="image"
          media={`(min-width: 1024px) and (max-width: ${tabletLMaxWidth})`}
          href={tabletL}
          fetchpriority={'high'}
        />
      )}
      {tabletP && (
        <link
          key={tabletP}
          rel="preload"
          as="image"
          media={`(min-width: 601px) and (max-width: ${tabletPMaxWidth})`}
          href={tabletP}
          fetchpriority={'high'}
        />
      )}
      {mobile && (
        <link
          key={mobile}
          rel="preload"
          as="image"
          media={`(max-width: ${mobileMaxWidth})`}
          href={mobile}
          fetchpriority={'high'}
        />
      )}

      {xxl && (
        <link
          key={mobile}
          rel="preload"
          as="image"
          media={'(min-width: 1440px)'}
          imageSrcSet={mobile}
          fetchpriority={'high'}
        />
      )}
      {xl && (
        <link
          key={xl}
          rel="preload"
          as="image"
          media={'(min-width: 1280px) and (max-width: 1439px)'}
          imageSrcSet={xl}
          fetchpriority={'high'}
        />
      )}
      {lg && (
        <link
          key={lg}
          rel="preload"
          as="image"
          media={'(min-width: 1024px) and (max-width: 1279px)'}
          imageSrcSet={lg}
          fetchpriority={'high'}
        />
      )}
      {md && (
        <link
          key={md}
          rel="preload"
          as="image"
          media={'(min-width: 768px) and (max-width: 1023px)'}
          imageSrcSet={md}
          fetchpriority={'high'}
        />
      )}
      {sm && (
        <link
          key={sm}
          rel="preload"
          as="image"
          media={'(min-width: 576px) and (max-width: 767px)'}
          imageSrcSet={sm}
          fetchpriority={'high'}
        />
      )}
      {xs && <link key={xs} rel="preload" as="image" media={'(max-width: 575px)'} href={xs} fetchpriority={'high'} />}
    </Head>
  )
}
