import { createContext, useMemo } from 'react'
import { IProduct, IServerProduct } from '@typesApp/product'

type ProductContextType = {
  product: IProduct | IServerProduct | undefined
}

type ProductProviderProps = { productData: ProductContextType } & React.PropsWithChildren

export const ProductContext = createContext<ProductContextType | undefined>(undefined)

export const ProductContextProvider: React.FC<ProductProviderProps> = props => {
  const { children, productData } = props
  const value = useMemo(() => productData, [productData])

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
}
