import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Log from '@services/Log'
import { AnchorProps, HandleClickProps } from './types/Anchor.types'
import styles from './styles/Anchor.module.scss'

export const Anchor: React.FC<AnchorProps> = ({
  children,
  className,
  external,
  href,
  variant = 'primary',
  ...otherProps
}) => {
  const router = useRouter()

  const handleClick = ({ event, url }: HandleClickProps) => {
    event.preventDefault()
    Log.info(`Routing in Anchor (src\\components\\UI-CSS\\Anchor\\index.tsx), ${window.location.pathname}, ${url}`)
    router.push(url)
  }

  const anchorProps = {
    className: clsx(styles[variant], className),
    href: typeof href === 'string' ? href : '/',
    onClick: event => {
      handleClick({ event, url: href })
    },
    ...otherProps,
  }

  // FIXME: prop types for Link and <a> are not the same
  return external ? <a {...anchorProps}>{children}</a> : <Link {...anchorProps}>{children}</Link>
}
