import styled from '@mui/material/styles/styled'
import { StyledButtonAsLink } from '../../../../components/UI'

export const CartRecapContainer = styled('div', {
  name: 'CartRecap',
  slot: 'Container',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  gap: theme.spacing(3),
  width: '100%',
}))

export const CartRecapItemWrapper = styled('div', {
  name: 'CartRecap',
  slot: 'ItemWrapper',
})(({ theme }) => ({
  border: 1,
  borderColor: theme.palette.custom.light3.grey,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
}))

export const CartRecapContentContainer = styled('div', {
  name: 'CartRecap',
  slot: 'ContentContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.custom.white,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

export const CartRecapFooterMessage = styled('div', {
  name: 'CartRecap',
  slot: 'FooterMessage',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  fontSize: theme.typography.body2.fontSize,
  alignContent: 'center',
  justifyContent: 'right',
}))

export const CartRecapEDD = styled('div', {
  name: 'CartRecap',
  slot: 'EDD',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.custom.alto,
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  padding: `${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(4)}`,
  span: {
    fontWeight: 'bold',
  },
}))

const cartRecapItemImageWidthMd = '304px'

export const CartRecapImageContainer = styled('div', {
  name: 'CartRecap',
  slot: 'ImageContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.secondary,
  position: 'relative',
  minWidth: 100,
  height: 237,
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  img: {
    width: '100%',
    height: 174,
    objectFit: 'contain',
    cursor: 'pointer',
  },

  [theme.breakpoints.up('sm')]: {
    padding: 0,
    height: 'auto',
    img: {
      width: cartRecapItemImageWidthMd,
    },
  },
}))

export const StyledRemoveButtonContainer = styled('div', {
  name: 'RemoveButtonCart',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: theme.spacing(6),
  marginTop: theme.spacing(4.5),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  fontWeight: '700',
  fontSize: theme.typography.subtitle1.fontSize,
}))

export const CartRecapImageFooter = styled('div', {
  name: 'CartRecap',
  slot: 'ImageFooter',
})(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'none',
  margin: `0 ${theme.spacing(4)}`,
  padding: `${theme.spacing(3.5)} 0`,

  [theme.breakpoints.up('sm')]: {
    display: 'initial',
  },
}))

export const CartRecapTop = styled('div', {
  name: 'CartRecap',
  slot: 'Top',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  '& > *': {
    width: 'auto',
  },
}))

export const CartRecapTopLeft = styled('div', {
  name: 'CartRecap',
  slot: 'TopLeft',
})(() => ({
  '& > *': {
    width: 'auto',
  },
}))

export const CartRecapTopRight = styled('div', {
  name: 'CartRecap',
  slot: 'TopRight',
})(() => ({}))

export const CartRecapBottom = styled('div', {
  name: 'CartRecap',
  slot: 'Bottom',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 'bold',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    padding: 0,
    textAlign: 'right',
  },
}))

export const CartRecapActions = styled('div', {
  name: 'CartRecap',
  slot: 'Actions',
})(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',
}))

export const CartRecapActionItem = styled('div', {
  name: 'CartRecap',
  slot: 'ActionItem',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 'bold',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    padding: 0,
    textAlign: 'right',
  },
  'span:nth-of-type(2)': {
    marginleft: theme.spacing(0),
    marginRight: theme.spacing(2),
    textDecoration: 'underline',
  },
}))

export const StyledRemoveButtonAsLink = styled(StyledButtonAsLink, {
  name: 'RemoveButtonCart',
  slot: 'AsLink ',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  '&:after': {
    background: theme.palette.text.dark.primary,
  },
}))

export const CartRecapRightColumn = styled('div', {
  name: 'CartRecap',
  slot: 'RightColumn',
  shouldForwardProp: prop => prop !== 'isClOrCla',
})<{ isClOrCla?: boolean }>(({ theme, isClOrCla }) => ({
  flex: `1 0 calc(100% - ${cartRecapItemImageWidthMd})`,
  padding: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    padding: isClOrCla
      ? `${theme.spacing(6)}`
      : `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
  },
}))

export const CartRecapRxInitialTotalPrice = styled('div', {
  name: 'CartRecap',
  slot: 'RXInitialTotalPrice',
})(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(2),
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'normal',
}))

export const CartRecapDataContent = styled('div', {
  shouldForwardProp: prop => prop !== 'isClAccessories',
  name: 'CartRecap',
  slot: 'DataContent',
})<{ isClAccessories?: boolean }>(({ theme, isClAccessories }) => ({
  flex: 1,
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isClAccessories ? 'space-around' : 'unset',
  },
}))

export const CartRecapData = styled('div', {
  name: 'CartRecap',
  slot: 'Data',
})(() => ({
  display: 'flex',
  flex: '1 0 50%',
  flexDirection: 'column',
}))

export const CartRecapModelPriceDataRow = styled('div', {
  name: 'CartRecap',
  slot: 'DataRow',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontSize: '0.75rem',
  lineHeight: 1,
}))

export const CartRecapTotalDataRowWrapper = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DataRowWrapper',
  shouldForwardProp: prop => prop !== 'isContactLenses',
})<{ isContactLenses?: boolean }>(({ isContactLenses }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flex: isContactLenses ? 0 : 1,
}))

export const CartRecapTotalDataRow = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DataRow',
  shouldForwardProp: prop => prop !== 'uppercase',
})<{ uppercase?: boolean }>(({ theme, uppercase }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontWeight: 600,
  fontSize: theme.typography.body1.fontSize,

  '& > div:first-of-type': {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    textTransform: uppercase ? 'uppercase' : 'lowercase',
  },
}))

export const CartRecapTotalBoxes = styled('span', {
  name: 'CartRecapTotal',
  slot: 'BoxCount',
})(() => ({
  fontSize: 14,
  fontWeight: 'normal',
  textTransform: 'lowercase',
}))

export const CartRecapTotalSection = styled('div', {
  name: 'CartRecapTotal',
  slot: 'TotalSection',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  fontSize: theme.typography.h4.fontSize,
}))

export const CartRecapAnnualSupplyRow = styled('div', {
  name: 'CartRecapTotal',
  slot: 'AnnualSupplyRow',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 'bold',
  paddingTop: theme.spacing(2),
}))

export const TotalWrapperUnit = styled('div', {
  name: 'CartRecapTotal',
  slot: 'TotalPrice',
})(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.h4.fontSize,
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'baseline',
}))

export const CartRecapTotalInitialPrice = styled('div', {
  name: 'CartRecapTotal',
  slot: 'InitialPrice',
})(() => ({
  fontSize: 18,
  textDecoration: 'line-through',
}))

export const CartRecapTotalDiscountedPrice = styled('div', {
  name: 'CartRecapTotal',
  slot: 'DiscountedPrice',
})(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 'bold',
}))

export const CartRecapDataRow = styled('div', {
  name: 'CartRecap',
  slot: 'DataRow',
})(({ theme }) => ({
  display: 'flex',
  fontSize: theme.typography.body2.fontSize,
}))

export const CartRecapDataRowLabel = styled('span', {
  name: 'CartRecap',
  slot: 'DataRowLabel',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.body2.fontSize,
  'span:first-of-type': {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  '&:after': {
    content: '""',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'initial',
  },
}))

export const ViewDetailsRowLabel = styled(CartRecapDataRowLabel, {
  name: 'CartRecap',
  slot: 'viewDetailsRowLabel',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontWeight: 700,
}))

export const CartRecapDataRowValue = styled('span', {
  name: 'CartRecap',
  slot: 'DataRowValue',
})(() => ({
  lineHeight: '1rem',
}))

export const CartRecapDataRowProductModel = styled('span', {
  name: 'CartRecap',
  slot: 'RowProductModel',
})(({ theme }) => ({
  color: theme.palette.text.light.secondary,
  fontSize: theme.typography.body2.fontSize,
}))

export const CartRecapDataRowPrice = styled('span', {
  name: 'CartRecap',
  slot: 'RowPrice',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  '.product-price': {
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(2),
  },
}))

export const CartRecapDataWrapper = styled('div', {
  name: 'CartRecap',
  slot: 'RecapDataWrapper',
})(() => ({
  fontSize: '0.75rem',
}))

export const CartRecapModelName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapModelName',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.light.secondary,
}))

export const CartRecapBrand = styled('div', {
  name: 'CartRecap',
  slot: 'BrandName',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
}))

export const StyledCartRecapDivider = styled('div', {
  name: 'CartRecap',
  slot: 'Divider',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
  height: 1,
  backgroundColor: theme.palette.background.light.tertiary,
}))

export const ContactLensQuantityPrescriptionMessage = styled('div', {
  name: 'ContactLensQuantity',
  slot: 'PrescriptionMessage',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(3),
  background: theme.palette.background.dark.quaternary,
  height: 32,
  fontSize: 10,
  fontWeight: 600,
  marginBottom: theme.spacing(6),
  svg: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(3),
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
}))

export const CartRecapRxImageFallbackContainer = styled('div', {
  name: 'CartRecap',
  slot: 'RxImageFallbackContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  margin: `0 ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}`,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

  backgroundColor: theme.palette.custom.white,
  borderRadius: theme.spacing(2),
  border: `solid 1px ${theme.palette.custom.alto}`,
  color: theme.palette.custom.cyprus,

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: theme.spacing(8),

    margin: `0 ${theme.spacing(2)}`,
  },

  img: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))

export const CartRecapRxImageFallbackTextContainer = styled('div', {
  name: 'CartRecap',
  slot: 'RxImageFallbackTextContainer',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  fontWeight: 'normal',

  h5: {
    margin: 0,

    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 'bold',
  },
}))

export const CartRecapPromoCodeLabelWrapper = styled('div', {
  name: 'CartRecapTotal',
  slot: 'PromoCodeLabel',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body1.fontSize,

  '& > div:first-of-type': {
    fontWeight: 600,
    fontSize: theme.typography.body2.fontSize,
  },
}))

export const CartRecapTotalFramesDataRow = styled('div', {
  name: 'CartRecapFramesTotal',
  slot: 'DataRow',
})(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  textTransform: 'uppercase',

  '& > div:first-of-type': {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600,
  },
}))

export const RxPrescriptionDetailsAddedMessage = styled(ContactLensQuantityPrescriptionMessage, {
  name: 'Rx',
  slot: 'PrescriptionDetailsAddedMessage',
})(() => ({
  cursor: 'pointer',
}))

export const CartRecapCLModelName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapModelName',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.dark.primary,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
}))

export const CartRecapCLBrandName = styled('div', {
  name: 'CartRecap',
  slot: 'RecapBrandName',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.light.secondary,
}))

export const CartRecapPriceContainer = styled('div', {
  name: 'CartRecap',
  slot: 'PriceContainer',
})(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const CartRecapInitialTotalPrice = styled('div', {
  name: 'CartRecap',
  slot: 'InitialTotalPrice',
})(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(2),
}))

export const CartRecapTotalPrice = styled('div', {
  name: 'CartRecap',
  slot: 'TotalPrice',
  shouldForwardProp: prop => prop !== 'withDiscount',
})({
  fontWeight: 600,
})

export const CartRecapPrescriptionAddedText = styled('span', {
  name: 'CartRecap',
  slot: 'PrescriptionAddedText',
})(() => ({
  textDecoration: 'underline',
}))

export const ContactLensQuantitySelectContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'QuantitySelectContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}))

export const ContactLensQuantitySelectWrapper = styled('div', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  flex: '0 1 100px',
  marginRight: theme.spacing(6),
}))

export const ContactLensQuantitySelectLabel = styled('span', {
  name: 'CartRecapItem',
  slot: 'ContactLensQuantitySelectWrapper',
})(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.subtitle1.fontSize,
  color: 'inherit',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  textTransform: 'uppercase',
}))

export const ContactLensPricePerBoxContainer = styled('div', {
  name: 'CartRecapItem',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  fontSize: theme.typography.subtitle1.fontSize,
  marginTop: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    alignItems: 'center',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: 0,
    marginLeft: 'auto',
  },
}))

export const CartRecapCutLabel = styled('span', {
  name: 'CartRecapItem',
  slot: 'CutLabel',
  shouldForwardProp: prop => prop !== 'withDiscount',
})<{ withDiscount?: boolean }>(({ withDiscount, theme }) => ({
  textDecoration: withDiscount ? 'line-through' : '',
  marginRight: withDiscount ? theme.spacing(1.25) : '',
}))

export const SoldOutMessage = styled('div', {
  name: 'CartRecap',
  slot: 'SoldOutMessage',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.light.secondary,
}))
