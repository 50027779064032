import React, { VideoHTMLAttributes, useEffect, useRef } from 'react'

export interface ProductVideoElement extends VideoHTMLAttributes<HTMLVideoElement> {
  play?: boolean
}

export const ProductVideo = (props: ProductVideoElement) => {
  const {muted = false, play = true, ...rest} = props
  const refVideo = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (refVideo?.current?.defaultMuted) {
      refVideo.current.defaultMuted = muted
    }
    if (refVideo?.current?.muted) {
      refVideo.current.muted = muted
    }
  }, [muted])

  useEffect(() => {
    if (play) {
      refVideo?.current?.play()
    } else {
      refVideo?.current?.pause()
    }
  }, [play])

  return (
    <video
      ref={refVideo}
      fetchpriority="high"
      {...rest}
    />
  )
}
