import ApiService from '../api'
import { IHeader, IPage, IPageStaticLanding } from '@features/cms/cms.type'
import { logger } from '../../../logging'
import { helper, Helper } from '../helpers'
import { BaseArgs, TimeTravelArgs } from '@typesApp/common'
import { ICommerceHclPage, IFooter, IMegaMenu, IQueryParams, ISitemap } from '@typesApp/cms'
import { TOverlayTextAlign } from '@typesApp/cmsPlacement/Placement'
import { cmsApiUrl } from '@utils/url'
import config from '@configs/index'
import { sanitizePageId } from '@utils/helpers'

const apiService = new ApiService(cmsApiUrl)

export interface CmsPageArgs extends BaseArgs, TimeTravelArgs {
  locale?: string
  excludePlacements?: string[]
  [key: `section.${string}.limit`]: string | undefined
  country: string
}

export interface SitemapPageArgs extends TimeTravelArgs {
  locale: string
  country: string
  hostname: string
}

export interface CmsStaticLandingArgs extends CmsPageArgs {
  pageId?: string
}

class CmsApiService {
  private api: ApiService
  private helper: Helper
  constructor(api: ApiService, helper: Helper) {
    this.api = api
    this.helper = helper
  }

  public getHomePage = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { country, previewDate, filterRulesLocaleOverride } = args
    const locale =
      args?.filterRulesLocaleOverride && args?.filterRulesLocaleOverride !== ''
        ? filterRulesLocaleOverride
        : args?.locale ?? config.defaultLocale
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      'section.main.limit': args?.['section.main.limit'],
      ...(previewDate && { previewDate }),
      ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
    })

    const url = `cms/live/home/content/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get home page data from cms ${url}`)
    return this.api.get<IPage>(url)
  }

  public getHeader = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { country, previewDate, filterRulesLocaleOverride, excludePlacements } = args
    const locale =
      args?.filterRulesLocaleOverride && args?.filterRulesLocaleOverride !== ''
        ? filterRulesLocaleOverride
        : args?.locale ?? config.defaultLocale
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      ...(previewDate && { previewDate }),
      ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
      excludePlacements,
    })

    const url = `cms/live/page/header/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get header data from cms ${url}`)
    return this.api.get<IHeader>(url)
  }

  public getMegaMenu = async (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country } = args
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      excludePlacements: args?.excludePlacements,
      previewDate: args?.previewDate,
      filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
      profile: 'header',
    })

    const url = `cms/live/page/mega-menu/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get mega menu data from cms ${url}`)
    return this.api.get<IMegaMenu>(url)
  }

  public getFooter = (args: CmsPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country } = args
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      filterRulesLocaleOverride: args.filterRulesLocaleOverride,
      previewDate: args.previewDate,
    })

    const url = `cms/live/page/footer/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`
    logger.info(`get footer data from cms ${url}`)
    return this.api.get<IFooter>(url)
  }

  public getPageStaticLanding = (args: CmsStaticLandingArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { country, previewDate, filterRulesLocaleOverride } = args
    const locale =
      args?.filterRulesLocaleOverride && args?.filterRulesLocaleOverride !== ''
        ? filterRulesLocaleOverride
        : args?.locale ?? config.defaultLocale
    const pageId = sanitizePageId(args?.pageId ?? '')
    const query = generateQuery({
      storeId: args?.storeId,
      langId: args?.langId,
      pageId,
      ...(previewDate && { previewDate }),
      ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
    })
    const url = `cms/live/page/content/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`
    logger.info(`get page static landing data from cms ${url}`)

    return this.api.get<IPageStaticLanding>(url)
  }

  public getPLPCommerce = (args: IQueryParams) => {
    const { generateQuery, getLang, storeName } = this.helper
    const query = generateQuery({
      externalId: args.externalId,
      breadcrumb: args.breadcrumb,
      storeId: args?.storeId,
      langId: args?.langId,
      ...(args?.previewDate && { previewDate: args?.previewDate }),
      ...(args?.filterRulesLocaleOverride && { filterRulesLocaleOverride: args?.filterRulesLocaleOverride }),
    })
    const url = `cms/live/plp/content/${storeName}/${getLang(args.locale)}?${query}`

    logger.info(`get plpCommerce data ${url}`)

    return this.api.get(url)
  }

  public getPDPCommerce = (args: IQueryParams) => {
    const { generateQuery, getLang, storeName } = this.helper
    const query = generateQuery({
      externalId: args.externalId,
      breadcrumb: args.breadcrumb,
      previewDate: args.previewDate,
      filterRulesLocaleOverride: args.filterRulesLocaleOverride,
      storeId: args?.storeId,
      langId: args?.langId,
      noEnrich: args?.noEnrich,
    })

    const url = `cms/live/pdp/content/${storeName}/${getLang(args.locale)}?${query}`

    logger.info(`get pdpCommerce data ${url}`)
    return this.api.get<ICommerceHclPage>(url)
  }

  public getSitemapPage = (args: SitemapPageArgs) => {
    const { getLang, getCountry, generateQuery, storeName } = this.helper
    const { locale, country, hostname } = args
    const query = generateQuery({
      baseUrl: hostname,
    })

    const url = `sitemap/${storeName}/${getLang(locale)}/${getCountry(country)}?${query}`

    logger.info(`get sitemap data from cms ${url}`)
    return this.api.get<ISitemap>(url)
  }

  public getTeaserOverlayTextAlign(teaserOverlay1TextAlign: TOverlayTextAlign) {
    if (!teaserOverlay1TextAlign) {
      return 'center'
    }

    // @ts-ignore
    // TODO: fix type
    return teaserOverlay1TextAlign === 'justified' ? 'justify' : teaserOverlay1TextAlign
  }
}

export const cmsApiService = new CmsApiService(apiService, helper)
