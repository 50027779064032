import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import LoadingSkeleton from './LoadingSkeleton'
import { Seo } from './Seo'
import {
  StyledPlayerContainer,
  StyledReactPlayer,
} from '@components/CmsPlacement/cms-common-media-v2/CMSCommonMedia.style'
import { cmsImageCrops } from '@constants/ui'
import {
  ContextWrapperData,
  PlacementContext,
} from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { isBanner } from '@utils/placements'
import { TPlayerBannerHook } from '@hooks/useBannerPlayer'
import { styled } from '@mui/material'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { IPreloadLinks } from '@typesApp/cmsPlacement/Media'
import { TMedia, isPictureMedia } from '@typesApp/teaser'
import { getSrcSetsImageFromCms, getVideoFromCMS } from '@utils/url'
import useBreakpoints from '@hooks/useBreakpoints'
import { PlacementContextType } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/types/PlacementContextWrapper.types'

interface CMSCommonMediaProps {
  alt?: string | undefined
  className?: string
  type: string
  media?: TMedia
  mobileBG?: TMedia
  tabletBG?: TMedia
  mediaHover?: TMedia
  playerBannerHook?: TPlayerBannerHook
  preloadLinks?: IPreloadLinks
  placementName?: string
  dataElementId?: string
  isFetchPriority?: boolean
  isLazy?: boolean
  hasHoverImage?: boolean
  fullHeight?: boolean
}

export interface PictureProps {
  alt?: string | undefined
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  isLazy?: boolean
  xxl?: string
  xl?: string
  lg?: string
  md?: string
  sm?: string
  xs?: string
  src?: string
  crops?: {
    [key: string]: {
      crop: string
      width: number
      height: number
      sizes?: { density: string; width: number; height: number }[]
    }
  }
}

const Picture: React.FC<PictureProps> = ({
  alt,
  deskL,
  deskS,
  tabletL,
  tabletP,
  xxl,
  xl,
  lg,
  md,
  sm,
  xs,
  src,
  isLazy = true,
  crops,
}) => {
  if (!src) return null
  return (
    <picture>
      {xxl ? (
        <source
          media="(min-width: 1440px)"
          srcSet={xxl}
          height={crops?.xxl?.sizes?.[0]?.height}
          width={crops?.xxl?.sizes?.[0]?.width}
        />
      ) : (
        deskL && (
          <source
            media="(min-width: 1440px)"
            srcSet={deskL}
            height={crops?.deskL?.height}
            width={crops?.deskL?.width}
          />
        )
      )}
      {xl ? (
        <source
          media="(min-width: 1280px) and (max-width: 1439px)"
          srcSet={xl}
          height={crops?.xl?.sizes?.[0]?.height}
          width={crops?.xl?.sizes?.[0]?.width}
        />
      ) : (
        deskS && (
          <source
            media="(min-width: 1280px) and (max-width: 1439px)"
            srcSet={deskS}
            height={crops?.deskS?.height}
            width={crops?.deskS?.width}
          />
        )
      )}
      {lg ? (
        <source
          media="(min-width: 1024px) and (max-width: 1279px)"
          srcSet={lg}
          height={crops?.lg?.sizes?.[0]?.height}
          width={crops?.lg?.sizes?.[0]?.width}
        />
      ) : (
        tabletL && (
          <source
            media="(min-width: 1024px) and (max-width: 1279px)"
            srcSet={tabletL}
            height={crops?.tabletL?.height}
            width={crops?.tabletL?.width}
          />
        )
      )}
      {md ? (
        <source
          media="(min-width: 768px) and (max-width: 1023px)"
          srcSet={md}
          height={crops?.md?.sizes?.[0]?.height}
          width={crops?.md?.sizes?.[0]?.width}
        />
      ) : (
        tabletP && (
          <source
            media="(min-width: 601px) and (max-width: 1023px)"
            srcSet={tabletP}
            height={crops?.tabletP?.height}
            width={crops?.tabletP?.width}
          />
        )
      )}
      {sm && (
        <source
          media="(min-width: 576px) and (max-width: 767px)"
          srcSet={sm}
          height={crops?.sm?.sizes?.[0]?.height}
          width={crops?.sm?.sizes?.[0]?.width}
        />
      )}

      <img
        src={src}
        alt={alt}
        // crossOrigin="anonymous"
        srcSet={xs}
        loading={isLazy ? 'lazy' : 'eager'}
        height={crops?.mobile?.height}
        width={crops?.mobile?.width}
      />
    </picture>
  )
}

const Container = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'BackgroundContainer',
  shouldForwardProp: prop => prop !== 'fullHeight' && prop !== 'withProducts',
})<{ fullHeight?: boolean; withProducts?: boolean }>(({ fullHeight, withProducts }) => ({
  overflow: 'hidden',
  height: fullHeight ? '100%' : 'auto',
  paddingTop: withProducts ? '75%' : '',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'bottom',
    display: 'block',
    transition: '0.3s ease-in-out',

    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
}))

export const CMSCommonMedia: React.FC<CMSCommonMediaProps> = props => {
  const {
    alt,
    className,
    isLazy = true,
    isFetchPriority = false,
    type,
    media,
    playerBannerHook,
    preloadLinks,
    dataElementId,
    fullHeight,
  } = props
  const { isMobile, isTablet, isTabletLandscape, isDesktopL, isDesktopS } = useBreakpoints()
  const video = media?.type === 'CMVideo' ? media : undefined
  const picture = isPictureMedia(media) ? media : undefined
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const { placement } = data ?? {}

  let pictureSrcSets = getSrcSetsImageFromCms(
    type,
    picture?.uriTemplate ?? video?.picture?.uriTemplate ?? '',
    hostnameUrls
  )

  const { mobile, tabletL, tabletP, deskL, deskS, xxl, xl, lg, md, sm, xs, src } = pictureSrcSets

  const urlCover = () => {
    switch (true) {
      case isDesktopL:
        return deskL
      case isDesktopS:
        return deskS
      case isTabletLandscape:
        return tabletL
      case isTablet:
        return tabletP
      case isMobile:
        return mobile
      default:
        return deskL
    }
  }

  return (
    <>
      {isFetchPriority ? (
        <Seo
          tabletL={preloadLinks?.tabletL || tabletL}
          tabletP={preloadLinks?.tabletP || tabletP}
          deskL={preloadLinks?.deskL || deskL}
          deskS={preloadLinks?.deskS || deskS}
          mobile={preloadLinks?.mobile || mobile}
          xxl={xxl}
          xl={xl}
          lg={lg}
          md={md}
          sm={sm}
          xs={xs}
        />
      ) : null}
      {playerBannerHook && video ? (
        <StyledPlayerContainer>
          <StyledReactPlayer
            url={getVideoFromCMS(video)}
            width="100%"
            height="100%"
            fallback={<LoadingSkeleton />}
            controls={!video?.hideControl}
            loop={!!video?.loop}
            muted={playerBannerHook.muted}
            playing={playerBannerHook.isPlaying}
            config={{
              file: {
                attributes: {
                  poster: urlCover(),
                },
              },
            }}
            {...playerBannerHook.events}
          />
        </StyledPlayerContainer>
      ) : (
        <Container
          fullHeight={fullHeight || isBanner(placement?.viewtype)}
          className={className}
          withProducts={type === 'BOX_WITH_2_PRODUCTS' || type === 'BOX_WITH_4_PRODUCTS'}
        >
          {!!isFetchPriority ? (
            <Seo
              tabletL={preloadLinks?.tabletL || tabletL}
              tabletP={preloadLinks?.tabletP || tabletP}
              deskL={preloadLinks?.deskL || deskL}
              deskS={preloadLinks?.deskS || deskS}
              mobile={preloadLinks?.mobile || mobile}
            />
          ) : null}
          <Picture
            alt={picture?.alt || alt}
            xxl={xxl}
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            deskL={deskL}
            deskS={deskS}
            tabletL={tabletL}
            tabletP={tabletP}
            src={src ?? mobile}
            isLazy={isLazy}
            data-element-id={dataElementId}
            crops={cmsImageCrops[type]}
          />
        </Container>
      )}
    </>
  )
}

export default CMSCommonMedia
