import { FC, useCallback, useContext } from 'react'
import clsx from 'clsx'

import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../constants/product'
import {
  determineAlgoliaPrice,
  getProductPrice,
  parseNumber,
  shouldShowAbsoluteDiscount,
} from '../../../foundation/algolia/algoliaPrice'
import CurrencyService from '../../../services/CurrencyService'
import { IProduct, ProductSoldOutStatus } from '../../../types/product'
import {
  ProductPriceWrapper,
  ProductPriceContainer,
  ProductPriceDetails,
  ProductPriceInitialPrice,
  ProductCurrentPriceContainer,
  ProductPriceCurrentPrice,
  ProductPriceDiscountBox,
  ProductPriceContainerPDP,
  ProductPriceLabel,
  ProductPriceInitialPricePDP,
  PriceSection,
  InitialPriceSection,
  DiscountedPriceSection,
  ProductPriceSoldOutBadge,
  ProductPriceSoldOutContainer,
} from './ProductPrice.style'
import theme from '../../../themes'
import WithSpinningLoader from '../../../components/with-loading/WithLoading'
import { useSite } from '../../../foundation/hooks/useSite'
import { Adjustment } from '../../../types/order'
import useBreakpoints from '../../../hooks/useBreakpoints'
import TotalLabelContainer from './TotalLabelContainer'
import { isSoldOutFeatureEnabled } from '@utils/common'
import { ProductContext } from '@components/PagesSeo/product/context/ProductContext'
import { getIsRoxable, getNormalizedProductType } from '@utils/productAttributes'
import { usePageType } from '@foundation/hooks/usePageType'
import { isAccessories, isContactLenses, isCLAccessories } from '@utils/product'
import { isEmpty } from 'lodash-es'
import { CartRecapTotalBoxes } from '@views/Cart/components/CartRecap/CartRecap.style'
import styles from './styles/ProductPriceAlgolia.module.scss'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

export interface ProductPriceProps {
  adjustments?: Adjustment[]
  className?: string
  ignorePageType?: boolean
  isCompact?: boolean
  isLoading?: boolean
  isRxOrder?: boolean
  isStickyBar?: boolean
  isTotal?: boolean
  isVerticalLayout?: boolean
  productQuantity?: string[]
  renderFramePriceLabel?: boolean
  soldOutStatus?: ProductSoldOutStatus
  totalBoxes?: number
  priceAfterAutoDiscount?: number
  showDiscountBadge?: boolean
  hideFromLabel?: boolean
  alignCenter?: boolean
}

// TODO: refactor this component
export const ProductPriceAlgolia: FC<ProductPriceProps> = props => {
  const {
    adjustments = [],
    className,
    ignorePageType,
    isCompact,
    isLoading,
    isRxOrder,
    isStickyBar,
    isTotal,
    isVerticalLayout,
    productQuantity,
    renderFramePriceLabel,
    soldOutStatus,
    totalBoxes = 0,
    showDiscountBadge = true,
    hideFromLabel,
    alignCenter,
  } = props
  const productContext = useContext(ProductContext)
  const product = productContext?.product

  const { t } = useTranslation()
  const { mySite } = useSite()
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))
  const { isViewportWidthUnder426, isViewportWidthUnder769 } = useBreakpoints()
  const { pageType } = usePageType()
  const isSoldOutEnabled = isSoldOutFeatureEnabled()
  const showSoldOutBadge =
    isSoldOutEnabled &&
    (soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK ||
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON)

  const customerSegments = useCustomerSegmentsUtil()

  const productType = getNormalizedProductType(product)
  const isPDP = pageType === 'pdp' && !ignorePageType
  const isCart = pageType === 'cart' && !ignorePageType
  const isPLP = pageType === 'plp' && !ignorePageType
  const isCL = isContactLenses(productType)
  const isCLAccessory = isAccessories(productType) || isCLAccessories(productType)
  const isRoxable = getIsRoxable(product ?? ({} as IProduct))

  const priceList = product?.x_price
  const isPriceAvailable = !!priceList && !isEmpty(priceList)
  const price = determineAlgoliaPrice(priceList, customerSegments, productType, isRoxable, isRxOrder, isPLP)
  const isAbsoluteDiscount = shouldShowAbsoluteDiscount(price)

  const isFramesPdp = isPDP && (productType === PRODUCT_TYPES_KEYS.FRAMES || productType === PRODUCT_TYPES_KEYS.OPTICAL)
  const isContactLensesPDP = isPDP && productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES

  const parsedQuantity = totalBoxes ? totalBoxes : productQuantity && parseInt(productQuantity[0])
  const { initialPrice, offerPrice, currency, discountPercentage, discountAbsolute } = getProductPrice(
    price,
    productQuantity,
    adjustments
  )

  // TODO: refactor into a pure helper function
  const formatPriceValue = useCallback(
    (value: number): string => {
      const shouldMaskTotal = value === 0 && isContactLensesPDP
      return CurrencyService.getFormattedPrice(
        mySite.locale,
        currency,
        value.toFixed(2),
        1,
        1,
        shouldMaskTotal
      ) as string
    },
    [currency, isContactLensesPDP, mySite.locale]
  )

  if (!productContext) return null

  const showFramePriceLabel = !isViewportWidthUnder426 && isViewportWidthUnder769 && renderFramePriceLabel
  const hasDiscount = discountAbsolute > 0

  const discountBadgeLabel = isAbsoluteDiscount
    ? t('ProductTile.Labels.AmountOff', { amount: (discountAbsolute * Math.max(totalBoxes, 1)).toFixed() })
    : t('ProductTile.Labels.PercentageOff', { amount: discountPercentage })

  const hidePriceSection =
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON

  // TODO: refactor into a pure helper function
  const getClItemAccessoryPrice = (
    priceValue: number,
    parsedQuantity?: number,
    totalBoxes?: number,
    isCL?: boolean,
    isCLAccessory?: boolean
  ): string => {
    isCLAccessory && parsedQuantity ? formatPriceValue(+priceValue * parsedQuantity) : formatPriceValue(+priceValue)
    switch (true) {
      case isCLAccessory && !!parsedQuantity:
        return formatPriceValue(+priceValue * (parsedQuantity || 1))
      case isCL:
        return formatPriceValue(+priceValue * (totalBoxes ?? 0))
      default:
        return formatPriceValue(+priceValue)
    }
  }

  if (!isPriceAvailable || hidePriceSection) {
    return null
  }

  if (isPDP) {
    return (
      <ProductPriceWrapper
        className={clsx(className, initialPrice !== undefined ? 'product-price' : 'product-price no-discount')}
      >
        <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}></WithSpinningLoader>
        <ProductPriceContainerPDP>
          <div>
            {isFramesPdp && (
              <ProductPriceLabel isBold={isPDP} isStickyBar={isStickyBar} showFramePriceLabel={showFramePriceLabel}>
                {t('Labels.Frame')} {showFramePriceLabel && t('Labels.Price')}
              </ProductPriceLabel>
            )}
            {!isFramesPdp && <TotalLabelContainer productType={productType} isPDP isStickyBar />}
          </div>
          {hasDiscount ? (
            <DiscountedPriceSection>
              <InitialPriceSection>
                <ProductPriceInitialPricePDP isStickyBar={isStickyBar}>
                  {!!initialPrice &&
                    (isFramesPdp || !!totalBoxes) &&
                    getClItemAccessoryPrice(
                      parseNumber(initialPrice),
                      parsedQuantity,
                      isFramesPdp ? 1 : totalBoxes,
                      isCL,
                      isCLAccessory
                    )}
                </ProductPriceInitialPricePDP>
                <PriceSection>
                  {offerPrice != null && (
                    <ProductPriceCurrentPrice isPDP isCompact={isCompact} isStickyBar={isStickyBar}>
                      {getClItemAccessoryPrice(
                        +offerPrice,
                        parsedQuantity,
                        isFramesPdp ? 1 : totalBoxes,
                        isCL,
                        isCLAccessory
                      )}
                    </ProductPriceCurrentPrice>
                  )}
                </PriceSection>
                {(isFramesPdp || !!totalBoxes) && (
                  <ProductPriceDiscountBox isPDP>{discountBadgeLabel}</ProductPriceDiscountBox>
                )}
              </InitialPriceSection>
            </DiscountedPriceSection>
          ) : (
            <PriceSection>
              {offerPrice != null && (
                <ProductPriceCurrentPrice isPDP isCompact={isCompact}>
                  {getClItemAccessoryPrice(+offerPrice, parsedQuantity, totalBoxes, isCL, isCLAccessory)}
                </ProductPriceCurrentPrice>
              )}
            </PriceSection>
          )}
        </ProductPriceContainerPDP>
      </ProductPriceWrapper>
    )
  }

  if (isCart) {
    return (
      <ProductPriceWrapper
        className={clsx(className, initialPrice !== undefined ? 'product-price' : 'product-price no-discount')}
        isCart
      >
        <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}></WithSpinningLoader>
        <ProductPriceContainer>
          {isTotal && <TotalLabelContainer productType={productType} isStickyBar />}
          {!!totalBoxes && (
            <CartRecapTotalBoxes>{` (${t('CartRecap.Labels.BoxCount', { count: totalBoxes })})`}</CartRecapTotalBoxes>
          )}
          <ProductPriceLabel showFramePriceLabel={showFramePriceLabel}>
            {showFramePriceLabel && t('Labels.Price')}
          </ProductPriceLabel>
          {/* TODO: refactor this ternary operator */}
          {hasDiscount ? (
            <DiscountedPriceSection>
              <InitialPriceSection isTotal={isTotal}>
                {!!initialPrice && (
                  <ProductPriceInitialPrice isLarge={isTotal} isTotal={isTotal}>
                    {getClItemAccessoryPrice(
                      parseNumber(initialPrice),
                      parsedQuantity,
                      isFramesPdp ? 1 : totalBoxes,
                      isCL,
                      isCLAccessory
                    )}
                  </ProductPriceInitialPrice>
                )}
                {offerPrice != null && (
                  <ProductPriceCurrentPrice isCompact={isCompact} isCart={isTotal}>
                    {getClItemAccessoryPrice(
                      +offerPrice,
                      parsedQuantity,
                      isFramesPdp ? 1 : totalBoxes,
                      isCL,
                      isCLAccessory
                    )}
                  </ProductPriceCurrentPrice>
                )}
                {showDiscountBadge && (!isTotal || isCL) && (
                  <ProductPriceDiscountBox isCartCL={isCL}>{discountBadgeLabel}</ProductPriceDiscountBox>
                )}
              </InitialPriceSection>
            </DiscountedPriceSection>
          ) : (
            <div className={styles.totalPriceContainer}>
              <PriceSection>
                {offerPrice != null && (
                  <ProductPriceCurrentPrice isCompact={isCompact} isCart={isTotal}>
                    {getClItemAccessoryPrice(+offerPrice, parsedQuantity, totalBoxes, isCL, isCLAccessory)}
                  </ProductPriceCurrentPrice>
                )}
              </PriceSection>
            </div>
          )}
        </ProductPriceContainer>
      </ProductPriceWrapper>
    )
  }

  return (
    <ProductPriceWrapper
      className={clsx(className, initialPrice !== undefined ? 'product-price' : 'product-price no-discount')}
    >
      <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}>
        <ProductPriceContainer isVerticalLayout={isVerticalLayout} alignCenter={alignCenter}>
          <ProductPriceDetails
            justifyContent={isVerticalLayout ? 'center' : 'flex-start'}
            isVerticalLayout={isVerticalLayout}
          >
            {(isRoxable || productType === 'contact-lenses') &&
              !hideFromLabel &&
              initialPrice !== offerPrice &&
              t('ProductTile.Labels.from')}
            {hasDiscount && <ProductPriceInitialPrice>{formatPriceValue(initialPrice)}</ProductPriceInitialPrice>}
            <ProductCurrentPriceContainer>
              <ProductPriceCurrentPrice isCompact={isCompact}>{formatPriceValue(offerPrice)}</ProductPriceCurrentPrice>
            </ProductCurrentPriceContainer>
            {(productType === 'contact-lenses' || productType === 'contact-lenses-accessories') &&
              t('ContactLenses.Labels.perBox')}
          </ProductPriceDetails>
          {(hasDiscount || !!price?.badge) && <ProductPriceDiscountBox>{discountBadgeLabel}</ProductPriceDiscountBox>}
          <ProductPriceSoldOutContainer>
            {showSoldOutBadge && (
              <ProductPriceSoldOutBadge isPDP={isPDP}>{t('ProductTile.Labels.soldout')}</ProductPriceSoldOutBadge>
            )}
          </ProductPriceSoldOutContainer>
        </ProductPriceContainer>
      </WithSpinningLoader>
    </ProductPriceWrapper>
  )
}
