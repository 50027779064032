import React, { MouseEventHandler } from 'react'
import clsx from 'clsx'
import { StyledLoader } from '@components/UI/Loader/Loader'
import { AnalyticsDataId } from '@typesApp/analyticsDataId'
import { onClickWithAnalytics } from '@utils/clickWithAnalytics'
import styles from './styles/Button.module.scss'

export type Button = AnalyticsDataId & {
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onMouseEnter?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  variant?: 'contained' | 'outlined'| 'outlined-light' | 'outlined-dark' | 'contained-light' | 'contained-dark'
  color?: 'primary' | 'secondary' | 'tertiary'
  size?: 'small' | 'medium' | 'big'
  isLoading?: boolean
  loaderSize?: number
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  children: React.ReactNode
}

export const Button: React.FC<Button> = ({
  onClick,
  onMouseEnter,
  className,
  children,
  style,
  variant = 'contained',
  color = 'primary',
  disabled = false,
  isLoading = false,
  loaderSize = 20,
  size = 'medium',
  'data-element-id': dataElementId,
  'data-description': dataDescription,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    onClickWithAnalytics(event, onClick, dataElementId, dataDescription)
  }

  return (
    <button
      className={clsx(styles.button, styles[variant], styles[color], styles[size], className, {
        isLoading,
      })}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      style={style}
      disabled={disabled}
      data-element-id={dataElementId}
      data-description={dataDescription}
    >
      {isLoading ? <StyledLoader size={loaderSize} /> : children}
    </button>
  )
}
