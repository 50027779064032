import { Link } from '@components/common/Link/Link'
import { onClickWithAnalytics } from '@utils/clickWithAnalytics'
import clsx from 'clsx'
import { TLinkAsButton } from './types'
import styles from './styles/index.module.scss'

export const LinkAsButton: React.FC<TLinkAsButton> = props => {
  const {
    variant = 'contained',
    color = 'primary',
    className,
    disabled,
    children,
    external,
    style,
    'data-element-id': data_element_id,
    'data-description': data_description,
    ...rest
  } = props
  return (
    <Link
      className={clsx(styles.button, styles[variant], styles[color], className, styles[disabled ? 'disabled' : ''])}
      dataElementId={data_element_id}
      dataDescription={data_description}
      target={external ? '_blank' : undefined}
      style={style}
      onClick={e => onClickWithAnalytics(e, undefined, data_element_id, data_description)}
      {...rest}
    >
      {children}
    </Link>
  )
}
