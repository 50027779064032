import useIsInViewport from '@hooks/useIsInViewport'
import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import { notUndefined } from '@utils/common'
import clsx from 'clsx'
import React, { useCallback, useRef } from 'react'
import { getMediaPictureUris, getVideoURL } from '../CmsMedia/helpers'
import { VideoPlayerIconSymbols } from './VideoPlayerControlsIcons'
import { useVideo } from './hooks/useVideo'
import { useVideoControl } from './hooks/useVideoControl'
import styles from './styles/index.module.scss'

type VideoPlayer = {
  lazyload?: boolean
  media: IVideoMedia[] | undefined
  onSetPlayingId?: (id?: string) => void
  playingId?: string
  canVideoBeClickable?: boolean
}

export const VideoPlayer: React.FC<VideoPlayer> = ({
  media,
  onSetPlayingId,
  playingId,
  canVideoBeClickable = false,
}) => {
  const videoRef = useRef<HTMLDivElement>(null)
  const inView = useIsInViewport(videoRef, {})

  const hasPictures = Boolean(getMediaPictureUris(media ?? []).filter(notUndefined).length)

  const { video: selectedVideo } = useVideo(media)

  // TODO: add <source> inside <video> to support video resolution based on media query and device type

  const isSelectedVideoClickable = Boolean(canVideoBeClickable && selectedVideo?.playOnHover)

  const { setRef, onHandlePlay, onHandlePause, onHandleMute, onHandleUnMute, isPlaying, isMuted } = useVideoControl({
    autoplay: selectedVideo?.autoplay ?? false,
    mute: selectedVideo?.autoplay ? true : selectedVideo?.mute ?? true,
    forceStop: Boolean(playingId && playingId !== selectedVideo?.teaserId),
  })

  const handlePlay = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event?.currentTarget?.tagName?.toLowerCase() === 'button') {
        event.preventDefault()
        event.stopPropagation()
      }

      if (isPlaying) {
        onHandlePause()
        return
      }
      onHandlePlay()
      onSetPlayingId && onSetPlayingId(selectedVideo?.teaserId)
    },
    [onHandlePlay, onSetPlayingId, selectedVideo?.teaserId, isPlaying, onHandlePause]
  )

  const handleMute = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      if (isMuted) {
        onHandleUnMute()
        return
      }
      onHandleMute()
    },
    [isMuted, onHandleMute, onHandleUnMute]
  )

  return (
    <div
      className={clsx('video-wrapper-sentinel', 'video-player', styles['video-player'], {
        'is-playing': isPlaying,
        'has-preview-picture': hasPictures,
      })}
      ref={videoRef}
    >
      <video
        className={clsx({
          'is-video-clickable': isSelectedVideoClickable,
        })}
        ref={setRef}
        autoPlay={selectedVideo?.autoplay}
        loop={selectedVideo?.loop}
        muted={isMuted}
        playsInline={true}
        // poster={generateCmsImageUrl(selectedVideo?.picture?.uriTemplate, 'desktop1280', 1280)}
        src={inView ? getVideoURL(selectedVideo) : ''}
        onClick={isSelectedVideoClickable ? handlePlay : undefined}
      />
      {selectedVideo?.hideControl ? null : (
        <div className="video-controls">
          <VideoPlayerIconSymbols />
          <div className="video-controls-btn">
            <button aria-label={isPlaying ? 'Pause' : 'Play'} onClick={handlePlay}>
              <svg>
                <use xlinkHref={`#${isPlaying ? 'pause-icon' : 'play-icon'}`} />
              </svg>
            </button>
            <button aria-label={isMuted ? 'Unmute' : 'Mute'} onClick={handleMute}>
              <svg>
                <use xlinkHref={`#${isMuted ? 'sound-off-icon' : 'sound-on-icon'}`} />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
