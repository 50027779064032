import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'

import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useGetProductImageWidth } from '@hooks/useGetProductImageWidth'
import { isAccessories, isCLAccessories, isContactLenses } from '@utils/product'
import { getBadges } from '@utils/productAttributes'
import { plpBadgesSelector } from '@redux/selectors/site'
import productTileUtils from '../ProductTile/ProductTileUtils'

import { PlacementCounter } from '@typesApp/common'
import { IProduct } from '@typesApp/product'

const useProductTile = (product: IProduct | undefined) => {
  const { pageType } = usePageType()
  const badgeSelector = useSelector(plpBadgesSelector)
  const { isRXEnabled, langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const [selectedClusterIndex] = useState<number>(0)

  // ClustersData
  const clusters = product?.cluster ?? []
  const clusterLength = clusters.length || 0
  const clusterSelected = useMemo<IProduct | undefined>(
    () => (clusters ? clusters[selectedClusterIndex] : product),
    [clusters?.length, selectedClusterIndex, product?.id]
  )

  // product attributes
  const productAttributes = clusterSelected ? productTileUtils.getAttributes(clusterSelected) : {}

  // product types
  const productType = productAttributes.productType ?? ''
  const isCLProduct = isContactLenses(productType) || isCLAccessories(productType)
  const isAccessoriesProduct = isAccessories(productType)

  // product images
  const productImageAttributes = {
    hasNoHoverImage: isCLProduct || isAccessoriesProduct,
    productImageWidth: useGetProductImageWidth(),
  }

  // product badges
  const { primaryBadge, secondaryBadges } = getBadges(clusterSelected, t, badgeSelector, isRXEnabled)
  const productBadges = {
    primaryBadge,
    secondaryBadges,
  }

  const productLinkTo = productTileUtils.getMocoLinkTo(clusterSelected!, langCode)

  // data elements
  const getTileDataElementId = (placementCounter: PlacementCounter | undefined) =>
    useMemo(() => {
      switch (pageType) {
        case 'search':
          return `SearchPanel_Content_Tile${placementCounter?.tileIndex}`
        case 'pdp':
          return `${placementCounter?.placementIndex}AlsoLike_Tile${placementCounter?.tileIndex}`
        default:
          return `${placementCounter?.placementIndex}Placement${placementCounter?.tabIndex ? `_Tab${placementCounter?.tabIndex}` : ''}_Tile${placementCounter?.tileIndex}_IMG`
      }
    }, [pageType])
  const tileDataElementId = getTileDataElementId(product?.placementCounter)

  return {
    clusterData: {
      clusters,
      clusterLength,
      clusterSelected,
      productAttributes,
      productBadges,
      productImageAttributes,
      productLinkTo,
    },
    selectedClusterIndex,
    tileDataElementId,
  }
}

export default useProductTile
